<template>
	<v-dialog
		v-model="showDialog"
		:fullscreen="$isMobileOnly"
		:persistent="persistent"
		:width="width"
		scrollable
		style="z-index: 9999"
		@keydown.esc="!persistent ? $emit('closeDialog') : ''"
		@click:outside="!persistent ? $emit('closeDialog') : ''"
	>
		<v-card color="background">
			<!--  Dialog header  -->
			<v-card-title
				:class="$isMobileOnly ? 'px-1 py-3' : 'px-4 py-3'"
				class="dialog-header"
			>
				<v-btn
					v-if="$isMobileOnly"
					class="px-2 mr-2"
					icon
					:light="!this.$vuetify.theme.dark"
					@click="$emit('closeDialog')"
				>
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>

				<!-- Header slot  -->
				<slot name="header"> </slot>

				<v-spacer />

				<!--  Close dialog  X -->
				<v-btn
					v-if="!$isMobileOnly"
					icon
					:light="!this.$vuetify.theme.dark"
					@click="$emit('closeDialog')"
				>
					<v-icon color="inherit">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text :class="noPadding ? 'pa-0' : 'pa-4'">
				<!-- Content slot -->
				<slot name="content"> </slot>
			</v-card-text>

			<!-- Card actions-->
			<div v-if="!noActions">
				<v-divider></v-divider>
				<v-card-actions class="py-2 px-4">
					<slot name="actions"> </slot>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

export default {
	name: "Dialog",
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [Number, String],
			default: "900px",
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		noPadding: {
			type: Boolean,
			default: false,
		},
		noActions: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit, root }) {
		const state = reactive({});

		const showDialog = computed({
			get: () => {
				return props.value;
			},
			set: (val) => {
				emit("input", val);
			},
		});

		return {
			showDialog,
		};
	},
};
</script>

<style scoped></style>
