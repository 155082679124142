<template>
	<!-- Forum View -->
	<v-progress-circular
		v-if="state.fetchingPosts"
		class="mx-auto mt-8"
		color="primary"
		size="60"
		width="6"
		indeterminate
	/>
	<div
		v-else
		style="max-width: 600px; overflow-y: auto; position: relative"
		class="mx-auto px-4 pt-4 pb-0"
		ref="forum-view"
	>
		<!-- Create Post -->
		<!--    <CreatePost/>-->

		<!--		TODO: Check scroll to end   -->
		<!-- Separator -->
		<!--    <div :class="$isMobileOnly ? 'mt-4' : 'mt-6'"></div>-->

		<!--		<v-scale-transition>-->
		<!--			<div class="text-center mb-4" v-show="state.hasScrolledToEnd">-->
		<!--				<v-progress-circular indeterminate color="primary" />-->
		<!--			</div>-->
		<!--		</v-scale-transition>-->

		<!-- Posts -->
		<!--		<div v-intersect="onIntersectOldest"></div>-->

		<div v-if="!state.forumType" class="d-flex justify-center mt-4">
			<v-btn
				v-for="(type, index) in state.forumTypeSelect"
				:key="`forum-type-${index}`"
				color="primary"
				outlined
				height="100"
				width="160"
				class="mx-2"
				style="background: rgba(51, 132, 229, 0.06)"
				@click="type.handler"
			>
				<div class="d-flex flex-column">
					<v-icon size="60">
						{{ type.icon }}
					</v-icon>
					<div class="mt-2">{{ type.label }}</div>
				</div>
			</v-btn>
		</div>

		<div v-else>
			<Post
				v-for="(post, index) in state.posts"
				:key="`post-${post.id}`"
				:class="index !== 1 ? 'mt-0 mb-4' : 'my-4'"
				:id="`post-${post.id}`"
				:ref="`post-${post.id}`"
				:post="post"
				@has-updates="handleNewPost(false)"
			/>

			<v-card
				outlined
				class="bg-card mt-4"
				v-intersect="onBottomIntersect"
				style="position: sticky; bottom: 0; z-index: 10"
			>
				<v-card-title style="opacity: 0.8; font-size: 1.2rem">
					Postim i ri
				</v-card-title>
				<v-card-text>
					<AddComment
						type="post"
						label="Krijo një post..."
						:forum-type="state.forumType"
						@new-post="handleNewPost"
					/>
				</v-card-text>
			</v-card>

			<!-- Scroll Btn -->
			<ScrollToBtn
				direction="down"
				:show="state.hasScrolledUp"
				@click="scrollToLatest('smooth')"
			/>
		</div>

		<!-- Parent confirm password -->
		<ConfirmPassword
			v-model="state.showParentConfirmDialog"
			@passwordIsValid="state.forumType = 'parent'"
		/>
	</div>
</template>

<script>
import { computed, reactive, watch, onUnmounted } from "@vue/composition-api";
import AddComment from "../components/forum/AddComment";
import Post from "../components/forum/Post";
import CreatePost from "../components/forum/CreatePost";
import ScrollToBtn from "../components/general/ScrollToBtn";
import ForumService from "../services/forum.service";
import ConfirmPassword from "../components/profile/ConfirmPassword";
import UserService from "../services/user.service";

export default {
	name: "Forum",
	components: { ConfirmPassword, ScrollToBtn, CreatePost, AddComment, Post },
	setup(props, { root, refs }) {
		const state = reactive({
			hasScrolledToEnd: false,
			hasScrolledUp: false,

			fetchingPosts: false,
			posts: computed(() => ForumService.getPosts()),

			showParentConfirmDialog: false,
			forumType: "",
			forumTypeSelect: [
				{
					type: "student",
					icon: "mdi-account-outline",
					label: "Nxënës",
					handler: () => {
						root.$router.push({ query: { type: "student" } });
						state.forumType = "student";
					},
				},
				{
					type: "student",
					icon: "mdi-account-child-outline",
					label: "Prind",
					handler: () => {
						const userData = UserService.getCurrentUserData();
						if (userData.role === "teacher") {
							state.forumType = "parent";
						} else {
							state.showParentConfirmDialog = true;
						}
					},
				},
			],

			fetchInterval: null,
		});

		watch(
			() => root.$route.query,
			(query) => {
				const type = query.type;
				if (type === "student") {
					state.forumType = type;
				}
				// else if (type === "parent") {
				// }
				// console.log({ query });
			},
			{ deep: true, immediate: true }
		);

		watch(
			() => state.forumType,
			async () => {
				if (state.forumType) {
					state.fetchingPosts = true;
					await fetchPosts();
					state.fetchingPosts = false;

					// TODO: Use pub/sub
					state.fetchInterval = setInterval(() => {
						fetchPosts();
					}, 2000);

					root.$nextTick(() => {
						scrollToLatest("smooth");
					});
				}
			},
			{ immediate: true }
		);

		onUnmounted(() => {
			clearInterval(state.fetchInterval);
		});

		async function fetchPosts() {
			try {
				const routeParams = root.$route.params;
				await ForumService.fetchPosts({
					classroomId: routeParams.classroomId,
					forumType: state.forumType,
				});
			} catch (err) {
				console.log(err);
			}
		}

		function onIntersectOldest(entries, observer) {
			if (entries[0].isIntersecting) {
				refs["forum-view"].scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}
			state.hasScrolledToEnd = entries[0].isIntersecting;
		}

		function onBottomIntersect(entries, observer) {
			state.hasScrolledUp = !entries[0].isIntersecting;
		}

		function scrollToLatest(behavior = null) {
			const scrollHeight = refs["forum-view"].scrollHeight;
			const innerHeight = refs["forum-view"].clientHeight;

			if (behavior) {
				refs["forum-view"].scrollTo({
					top: scrollHeight - innerHeight,
					behavior: behavior,
				});
			} else {
				refs["forum-view"].scrollTop = scrollHeight - innerHeight;
			}
		}

		// TODO: Maybe no need to fetch
		const handleNewPost = async (scroll = true) => {
			await fetchPosts();

			if (scroll) {
				root.$nextTick(() => {
					scrollToLatest();
				});
			}
		};

		return {
			state,

			onIntersectOldest,
			onBottomIntersect,

			scrollToLatest,
			handleNewPost,
		};
	},
};
</script>

<style scoped></style>
