import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"z-index":"9999"},attrs:{"fullscreen":_vm.$isMobileOnly,"persistent":_vm.persistent,"width":_vm.width,"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }!_vm.persistent ? _vm.$emit('closeDialog') : ''},"click:outside":function($event){!_vm.persistent ? _vm.$emit('closeDialog') : ''}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"color":"background"}},[_c(VCardTitle,{staticClass:"dialog-header",class:_vm.$isMobileOnly ? 'px-1 py-3' : 'px-4 py-3'},[(_vm.$isMobileOnly)?_c(VBtn,{staticClass:"px-2 mr-2",attrs:{"icon":"","light":!this.$vuetify.theme.dark},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1):_vm._e(),_vm._t("header"),_c(VSpacer),(!_vm.$isMobileOnly)?_c(VBtn,{attrs:{"icon":"","light":!this.$vuetify.theme.dark},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c(VIcon,{attrs:{"color":"inherit"}},[_vm._v("mdi-close")])],1):_vm._e()],2),_c(VCardText,{class:_vm.noPadding ? 'pa-0' : 'pa-4'},[_vm._t("content")],2),(!_vm.noActions)?_c('div',[_c(VDivider),_c(VCardActions,{staticClass:"py-2 px-4"},[_vm._t("actions")],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }