<template>
	<div>
		<div class="d-flex align-end">
			<v-spacer />
			<v-btn
				v-if="isGrantedAccess('createAny', 'classroom')"
				color="primary"
				@click="state.showDialog = !state.showDialog"
			>
				<v-icon left>mdi-plus</v-icon>
				Krijo diskutim
			</v-btn>
		</div>

		<Dialog
			v-model="state.showDialog"
			:persistent="true"
			width="800px"
			@closeDialog="
				$refs.createPostForm.reset();
				state.showDialog = false;
			"
		>
			<!--  Dialog header  -->
			<template v-slot:header>
				Krijo një diskutim të ri
			</template>

			<!--  Content -->
			<template v-slot:content>
				<!-- Form  -->
				<v-form ref="createPostForm" class="mt-1" @submit.prevent="">
					<!-- Form wrapper -->
					<v-row class="flex-wrap">
						<v-col :cols="$isMobileOnly ? '12' : '4'"> </v-col>

						<!-- Group Year select -->
						<v-col :cols="$isMobileOnly ? '12' : '3'"> </v-col>
					</v-row>
				</v-form>
			</template>

			<!-- Actions -->
			<template v-slot:actions>
				<v-spacer></v-spacer>
				<v-btn
					:class="{ 'full-width': $isMobileOnly }"
					:disabled="state.loading"
					:loading="state.loading"
					color="primary"
					@click="onSave"
				>
					Ruaj
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import Dialog from "../general/Dialog";

export default {
	name: "CreatePost",
	components: { Dialog },
	setup(props, { root, emit, refs }) {
		const state = reactive({
			showDialog: false
		});

		function onSave() {
			console.log("Save");
		}

		return {
			state,

			onSave
		};
	}
};
</script>

<style scoped></style>
