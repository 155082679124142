import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-end"},[_c(VSpacer),(_vm.isGrantedAccess('createAny', 'classroom'))?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.state.showDialog = !_vm.state.showDialog}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Krijo diskutim ")],1):_vm._e()],1),_c('Dialog',{attrs:{"persistent":true,"width":"800px"},on:{"closeDialog":function($event){_vm.$refs.createPostForm.reset();
			_vm.state.showDialog = false;}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Krijo një diskutim të ri ")]},proxy:true},{key:"content",fn:function(){return [_c(VForm,{ref:"createPostForm",staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,{staticClass:"flex-wrap"},[_c(VCol,{attrs:{"cols":_vm.$isMobileOnly ? '12' : '4'}}),_c(VCol,{attrs:{"cols":_vm.$isMobileOnly ? '12' : '3'}})],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VSpacer),_c(VBtn,{class:{ 'full-width': _vm.$isMobileOnly },attrs:{"disabled":_vm.state.loading,"loading":_vm.state.loading,"color":"primary"},on:{"click":_vm.onSave}},[_vm._v(" Ruaj ")])]},proxy:true}]),model:{value:(_vm.state.showDialog),callback:function ($$v) {_vm.$set(_vm.state, "showDialog", $$v)},expression:"state.showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }