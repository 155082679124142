<template>
	<v-dialog
		v-model="showDialog"
		:persistent="persistent"
		:width="width"
		scrollable
		style="z-index: 10000"
		@keydown.esc="!persistent ? $emit('closeDialog') : ''"
		@click:outside="!persistent ? $emit('closeDialog') : ''"
	>
		<v-card color="background">
			<!--  Dialog header  -->
			<v-card-title
				:class="[$isMobileOnly ? 'px-2 pl-4 py-2' : 'px-4 py-3']"
				class="dialog-header"
			>
				<!-- Header slot  -->
				<slot name="header"> </slot>

				<v-spacer />

				<!--  Close dialog  X -->
				<v-btn
					:light="!this.$vuetify.theme.dark"
					icon
					@click="$emit('closeDialog')"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Dialog content -->
			<v-card-text :class="noPadding ? 'pa-0' : 'pa-4'" style="font-size: 16px">
				<!-- Content slot -->
				<slot name="content"> </slot>
			</v-card-text>

			<!-- Card actions-->
<!--			<v-divider></v-divider>-->
			<v-card-actions :class="$isMobileOnly ? 'pa-2' : 'pa-3'">
				<slot name="actions"> </slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";

export default {
	name: "ConfirmDialog",
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "info",
		},
		persistent: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [String, Number],
			default: 500,
		},
		noPadding: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit, root }) {
		const state = reactive({});

		const showDialog = computed({
			get: () => {
				return props.value;
			},
			set: (val) => {
				emit("input", val);
			},
		});

		return {
			showDialog,
		};
	},
};
</script>

<style lang="scss" scoped>
.success {
	background-color: var(--v-success);
}

.error {
	background-color: var(--v-error);
}

.info {
	background-color: var(--v-primary-base) !important;
	color: #ffffff;
}
</style>
