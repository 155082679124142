<template>
	<ConfirmDialog
		v-model="showDialog"
		type="info"
		width="400px"
		@closeDialog="onClose"
	>
		<template v-slot:header>
			Konfirmo fjalëkalimin
		</template>

		<template v-slot:content>
			<v-form
				id="confirmPasswordForm"
				ref="confirmPasswordForm"
				class="pt-1"
				@submit.prevent="checkPassword"
			>
				<v-row>
					<v-col cols="12">
						<v-text-field
							v-model="state.password"
							:append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:type="state.showPassword ? 'text' : 'password'"
							ref="passwordField"
							autofocus
							dense
							hide-details="auto"
							label="Fjalëkalimi aktual"
							outlined
							@input="state.showError = false"
							@click:append="state.showPassword = !state.showPassword"
						></v-text-field>
					</v-col>
				</v-row>

				<Alert :msg="state.errorMessage" :show="state.showError">
					{{ state.errorMessage }}
				</Alert>
			</v-form>
		</template>

		<template v-slot:actions>
			<v-btn
				:disabled="state.isLoading"
				:loading="state.isLoading"
				class="full-width"
				color="primary"
				form="confirmPasswordForm"
				type="submit"
				@click="checkPassword"
				depressed
			>
				Konfirmo
			</v-btn>
		</template>
	</ConfirmDialog>
</template>

<script>
import ConfirmDialog from "../general/ConfirmDialog";
import Alert from "../general/Alert";
import { computed, reactive } from "@vue/composition-api";
import { displayErrorMessage } from "../../utilities/error-msg.utilities";

export default {
	name: "ConfirmPassword",
	components: { ConfirmDialog, Alert },
	props: ["value"],
	setup(props, { root, refs, emit }) {
		const state = reactive({
			isLoading: false,

			showPassword: false,
			password: null,

			errorMessage: "",
			showError: false
		});

		const showDialog = computed({
			get: () => props.value,
			set: value => {
				emit("input", value);
			}
		});

		async function checkPassword() {
			state.isLoading = true;

			try {
				const response = await root.$store.dispatch(
					"auth/checkPassword",
					state.password
				);
				if (response.status === 200) {
					showDialog.value = false;
					refs["confirmPasswordForm"]?.reset();
					emit("passwordIsValid");
				}
			} catch (err) {
				state.errorMessage = displayErrorMessage(err);
				refs["passwordField"]?.focus();
				state.password = null;
				state.showError = true;
			} finally {
				setTimeout(() => {
					state.isLoading = false;
				}, 300);
			}
		}

		function onClose() {
			showDialog.value = false;
			refs["confirmPasswordForm"]?.reset();
		}

		return {
			state,

			showDialog,
			checkPassword,

			onClose
		};
	}
};
</script>

<style scoped></style>
