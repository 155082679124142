import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"z-index":"10000"},attrs:{"persistent":_vm.persistent,"width":_vm.width,"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }!_vm.persistent ? _vm.$emit('closeDialog') : ''},"click:outside":function($event){!_vm.persistent ? _vm.$emit('closeDialog') : ''}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"color":"background"}},[_c(VCardTitle,{staticClass:"dialog-header",class:[_vm.$isMobileOnly ? 'px-2 pl-4 py-2' : 'px-4 py-3']},[_vm._t("header"),_c(VSpacer),_c(VBtn,{attrs:{"light":!this.$vuetify.theme.dark,"icon":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],2),_c(VCardText,{class:_vm.noPadding ? 'pa-0' : 'pa-4',staticStyle:{"font-size":"16px"}},[_vm._t("content")],2),_c(VCardActions,{class:_vm.$isMobileOnly ? 'pa-2' : 'pa-3'},[_vm._t("actions")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }