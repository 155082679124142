<template>
	<v-alert
		:icon="icon"
		:type="type"
		:value="show"
		class="custom-alert text-center full-width mt-2 mb-0"
		dense
		outlined
		transition="scale-transition"
	>
		{{ msg }}
	</v-alert>
</template>

<script>
export default {
	name: "Alert",
	props: {
		type: {
			type: String,
			default: "error"
		},
		icon: {
			type: [Boolean, String],
			default: false
		},
		msg: {
			type: String,
			default: ""
		},
		show: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.custom-alert::v-deep {
	.v-alert__wrapper {
		align-items: center !important;

		i.v-icon {
			align-self: center !important;
		}

		.v-alert__content {
			flex: auto !important;
			width: fit-content;
		}
	}
}
</style>
