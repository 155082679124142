export function displayErrorMessage(err) {
	switch (err.message) {
		case "NETWORK_ERROR":
			return "Mungon lidhja me serverin.";
		case "MISSING_CREDENTIALS":
			return "Mungojnë kredencialet.";
		case "USER_NOT_FOUND":
			return "Përdoruesi nuk ekziston.";
		case "USER_EXISTS":
			return "Përdoruesi ekziston. Ju lutemi hyni me kredencialet e juaja.";
		case "WRONG_PASSWORD":
			return "Fjalëkalimi është i gabuar.";
		default:
			return "Ka ndodhur një problem. Ju lutemi provoni përsëri!";
	}
}
