<template>
	<v-scale-transition origin="center">
		<v-btn
			v-show="show"
			color="primary"
			fab
			fixed
			bottom
			right
			style="opacity: 0.8;"
			@click="$emit('click')"
		>
			<v-icon>{{ "mdi-chevron-" + direction }}</v-icon>
		</v-btn>
	</v-scale-transition>
</template>

<script>
export default {
	name: "ScrollToBtn",
	props: {
		show: {
			type: Boolean,
			default: true
		},
		direction: {
			type: String,
			default: "up"
		}
	}
};
</script>

<style scoped></style>
